/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #9ea1ad;
$black: #161616;
$primary: #e4e4e4;
$secondary: rgba($primary, 0.7);
$ternary: #999696;
$border: #161616;
$gray: #161616;
$accent: #7861ff;
$gradient: linear-gradient(to right, #5884ee, #00ff00);
