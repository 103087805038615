@import '../../scss/default/color_variable';
.cs-testimonial.cs-style1 {
  position: relative;
  padding-left: 70px;
  padding-top: 70px;
  .cs-testimonial_quote {
    position: absolute;
    top: 0;
    left: 1px;
    font-size: 42px;
    color: $primary;
  }
  .cs-testimonial_text {
    font-size: 22px;
    line-height: 1.5em;
    font-weight: 600;
    color: $primary;
  }
  .cs-rating {
    margin-top: 24px;
  }
  .cs-testimonial_avatar_name {
    font-size: 22px;
    margin-bottom: 0;
    margin-top: 70px;
  }
  .cs-testimonial_avatar_designation {
    margin-top: 10px;
    &::before {
      content: '';
      height: 2px;
      width: 20px;
      background-color: #d9d9d9;
      display: inline-block;
      position: relative;
      top: -4px;
      border-radius: 3px;
      margin-right: 10px;
    }
  }
}
.cs-testimonial_slider_2 {
  text-align: center;
  .cs-testimonial_slider_left {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .cs-testimonial.cs-style1 {
    padding-left: 0;
    padding-top: 0px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    .cs-testimonial_quote {
      position: initial;
      margin-bottom: 20px;
    }
    .cs-rating {
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
    }
    .cs-testimonial_avatar_name {
      margin-top: 30px;
    }
    .cs-testimonial_avatar_designation {
      margin-top: 10px;
    }
  }
  .cs-testimonial.cs-style3 {
    padding: 50px;
    border-radius: 30px;
    background: linear-gradient(267deg, #161616 0%, #080808 100%);
    @media (max-width: 1080px) {
      padding: 30px;
    }
    .cs-client_img {
      flex: none;
      width: 6px;
      height: 60px;
      border-radius: 100%;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .cs-client_info {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 45px;
    }
    .cs-client_review {
      margin-bottom: 40px;
    }
    .cs-client_name {
      font-size: 22px;
      margin-bottom: 0;
      line-height: 1.5em;
    }
    .cs-client_designation {
      font-weight: 600;
    }
  }
  @media (max-width: 420px) {
    margin-bottom: -30px;
    .cs-testimonial.cs-style1 {
      padding-bottom: 70px;
    }
    .cs-arrow_style1 {
      .slick-prev,
      .slick-next {
        bottom: 0;
      }
    }
  }
  @media (min-width: 421px) {
    .cs-arrow_style1 {
      .slick-prev,
      .slick-next {
        top: 0;
        border: none;
        width: initial;
        &:hover {
          background-color: transparent;
          opacity: 0.8;
        }
      }
      .slick-prev {
        left: 0;
        right: initial;
      }
    }
  }
}
.cs-testimonial.cs-style3 {
  padding: 50px;
  border-radius: 30px;
  background: linear-gradient(267deg, #161616 0%, #080808 100%);
  @media (max-width: 991px) {
    padding: 30px;
  }
  .cs-client_img {
    flex: none;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .cs-client_info {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 45px;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
  .cs-client_review {
    margin-bottom: 40px;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
  .cs-client_name {
    font-size: 22px;
    margin-bottom: 0;
    line-height: 1.5em;
  }
  .cs-client_designation {
    font-weight: 600;
  }
}
.cs-arrow_style2 .cs-testimonial.cs-style3 {
  margin-bottom: 70px;
}
@media screen and (max-width: 1380px) {
  .cs-testimonial.cs-style1 .cs-testimonial_text,
  .cs-testimonial.cs-style1 .cs-testimonial_avatar_name {
    font-size: 20px;
  }
}
@media screen and (max-width: 991px) {
  .cs-testimonial.cs-style1 {
    padding-left: 20px;
    padding-top: 60px;
  }
  .cs-testimonial.cs-style1 .cs-testimonial_avatar_name {
    margin-top: 30px;
  }
}
